export const navItemsData = [
  {
    path: "/",
    name: "Anasayfa",
  },

  {
    path: "/menu",
    name: "Menü",
  },

  {
    path: "/iletisim",
    name: "İletişim",
  },
]
